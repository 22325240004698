import React from "react"
import UnderConstruction from "../components/UnderConstruction"

const Delivery = () => {
    return (
        <>
            <UnderConstruction />
        </>
    )
}

export default Delivery
